import './App.css';
import { React, Component } from 'react'
import PasswordProtect from './PasswordProtect'

class App extends Component {
    constructor(props) {
      super(props)
      this.state = {
          show: false,
          page: true,
      }
      document.title = "Arushi B-Day !!"
      this.changeShow = this.changeShow.bind(this)
    }

    changeShow(show) {
      this.setState({show})
    }

    render() {
      const { changeShow } = this
      const { show, page } = this.state

      return(
        <div className='root-container'>
          {!show && (
            <PasswordProtect password="shobeautiful" onSubmit={changeShow}></PasswordProtect>
          )}
          {show && (
            <>
            <div className='button-container'>
                <button onClick={() => this.setState({page: !page})}>Hello</button>
              </div>
            {page && (
            <div>
              <div className="haiku-section">
                <div className="haiku">
                  <p>There upon your lips,</p>
                  <p>Starlight stops and stares at me.</p>
                  <p>Smile, and Earth is free!</p>
                </div>
                <div className="haiku">
                  <p>Suspend one second:</p>
                  <p>No time to stand, sit, or speak.</p>
                  <p>But my love, it reams!</p>
                </div>
                <div className="haiku">
                  <p>What can contain you?</p>
                  <p>No sun nor moon rises on</p>
                  <p>Your boundless beauty.</p>
                </div>
              </div>
              <div className="birthday-section">
                <h1 className="birthday-header">Happy Birthday Arushi!</h1>
                <img className="aparekh" src="/aparekh.jpg"></img>
                <img className="aparekh" src="/bday.gif"></img>
                <p>Hope you had a great 21st Birthday!</p>
                <p>Love you!</p>
              </div>
              <div className="camera-section">
                <h1 className="info-header">Resources for Camera</h1>
                <p>Things to Read:</p>
                <ul>
                  <li><a target="_blank" href="https://www.cameramanuals.org/canon_pdf/canon_ii_d_iv_s2.pdf">The Original Camera Guide</a></li>
                  <li><a target="_blank" href="https://lesdeuxpiedsdehors.com/en/exposure-in-photography/">Photography Fundamentals</a></li>
                  <li><a target="_blank" href="https://www.shopmoment.com/reviews/beginners-master-film-guide">Film Photography Guide</a></li>
                  <li><a target="_blank" href="https://www.analog.cafe/r/what-is-a-rangefinder-camera-and-how-to-use-it-o69w">Rangefinder Guide</a></li>
                </ul>
                <p>Videos to Watch:</p>
                <ul>
                  <li><a target="_blank" href="https://www.youtube.com/watch?v=kYFSuGNzuFg">Film Photography Tutorial</a></li>
                  <li><a target="_blank" href="https://www.youtube.com/watch?v=JwQ-luuYcyM">How Rangefinder Focus Works</a></li>
                  <li><a target="_blank" href="https://www.youtube.com/watch?v=xrfSTCmhVOc">Loading Rangefinder</a></li>
                </ul>
              </div>
            </div>
            )}

            {!page && (
              <div className='letter'>
                <h1>Dear Arushi,</h1>
                <p>Happy birthday, my baby! I hope you have the best day ever!</p>
                <br></br>
                <p>It's hard to imagine that I published this website almost a year ago to celebrate the same occasion. How different we were back then, and still unfamiliar to each other! Each time I saw you that summer, it was like I was a child eager to have another sample of your flavor, irresistibly drawn to seeing you again.</p>
                <br></br>
                <p>I know we've celebrated our one-year anniversary, but to me this occasion reminds me so much of that weekend an August ago. At the time we were unaware how special that moment was, how special our relationship had become, and it made me understand how an instant with you can pass for miles in my memory. For me, there was the time before your twenty-first birthday, and the time after——that's it. As time ran along into our last year at Brown, each moment had an unsurpassed potency and the experiences we've had have only proven to me that each day with you could be another August day.</p>
                <br></br>
                <p>I'm writing this before I've reached New York for your brithday. I can't wait to have so much fun this weekend with you and make another birthday weekend memorable. At least this time, I'll be here for your birthday!</p>
                <br></br>
                <p>As you're ready to set twenty-one aside, just think about how much we've grown this past year, but more importantly, how much you've grown. You are a much more confident and mature person than the girl I knew last year. I have no doubt that whatever match life sets against you, you will come out the better woman. Just remember that you are capable of extraordinary things and that you possess an exceptional ability to love. That alone will take you far.</p>
                <br></br>
                <p>I've attached some of my favorite images from the past year. Enjoy, mofo.</p>
                <h1>Your Love, Sai</h1>
                <br></br>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => 
                  <img className="aparekh" src={"/ap-" + x + ".jpg"}></img>)}
              </div>
            )}
            </>
          )}
        </div>
      );
    }
}

export default App;
