import { React, Component } from 'react'
import './App.css';

class PasswordProtect extends Component {
    constructor(props) {
        super(props)
        this.checkPassword = this.checkPassword.bind(this)
    }

    checkPassword() {
        const { password, onSubmit } = this.props

        const input = document.getElementById("password-box").value;

        if (input == password) {
            onSubmit(true)
        }
    }

    render() {
        const { checkPassword } = this

        return(
            <div>
                <div className="password-box">
                    <form className="form" onSubmit={checkPassword}>
                        <h1>Enter Password</h1>
                        <input id="password-box"></input>
                    </form>
                    <button id="submit" onClick={checkPassword}>Submit</button>
                </div>
            </div>
        );
    }
}

export default PasswordProtect;